import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import LayoutPost from "../components/LayoutPost";

const ThoughtsPost = ({ data }) => {
  return <LayoutPost data={data} />;
};

ThoughtsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ThoughtsPost;

export const pageQuery = graphql`
  query ThoughtsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "DD of MMMM YYYY")
        title
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1152, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
